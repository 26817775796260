var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.isDataLoading,"headers":_vm.headers,"items":_vm.values,"dense":"","items-per-page":100,"sort-by":'date',"sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Portfell")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Lisa uus ")])]}}]),model:{value:(_vm.formVisible),callback:function ($$v) {_vm.formVisible=$$v},expression:"formVisible"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.editedItem.date,"clearable":"","label":"Väärtuskuupäev","readonly":"","outlined":"","disabled":!!_vm.editedItem.id},on:{"click:clear":function($event){_vm.editedItem.date = null}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.isDateAllowed,"events":_vm.historyDates,"event-color":"green lighten-1"},on:{"change":function($event){_vm.showDatePicker = false}},model:{value:(_vm.editedItem.date),callback:function ($$v) {_vm.$set(_vm.editedItem, "date", $$v)},expression:"editedItem.date"}})],1),_c('validation-provider',{attrs:{"name":"Portfelli väärtus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Portfelli väärtus","hint":"Portfelli väärtus väärtuskuupäeva lõpu seisuga","required":"","outlined":"","persistent-hint":""},model:{value:(_vm.editedItem.portfolioValue),callback:function ($$v) {_vm.$set(_vm.editedItem, "portfolioValue", $$v)},expression:"editedItem.portfolioValue"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Summa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Lisatud/välja võetud summa","hint":"Summa, mille oled portfelli lisanud või välja võtnud perioodil alates eelmisest väärtuskuupäevast kui praeguse väärtuskuupäevani. Juhul kui lisasid raha portfelli, on see postiivne number, juhul kui võtsid raha välja, siis negatiivne.","outlined":"","persistent-hint":""},model:{value:(_vm.editedItem.externalFlow),callback:function ($$v) {_vm.$set(_vm.editedItem, "externalFlow", $$v)},expression:"editedItem.externalFlow"}})]}}],null,true)})],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Sulge ")]),_c('help'),(!_vm.isExample)?_c('v-btn',{attrs:{"color":"green","dark":""},on:{"click":_vm.save}},[_vm._v(" Salvesta ")]):_vm._e()],1)],1)],1)],1)]},proxy:true},{key:"item.portfolioValue",fn:function(ref){
var value = ref.value;
return [_c('value',{attrs:{"value":value,"type":"money","forceNeutral":true,"bold":false}})]}},{key:"item.externalFlow",fn:function(ref){
var value = ref.value;
return [_c('value',{attrs:{"value":value,"type":"money","bold":false}})]}},{key:"item.nav",fn:function(ref){
var value = ref.value;
return [_c('value',{attrs:{"value":value,"type":"nav","forceNeutral":true,"bold":false}})]}},{key:"item.navChange",fn:function(ref){
var value = ref.value;
return [_c('value',{attrs:{"value":value,"type":"percent"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.isExample)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }