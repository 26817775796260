export const PortfolioMixin = {
  data () {
    return {
      isDataLoading: false,
      portfolio: null,
      history: []
    }
  },
  computed: {
    portfolioId () {
      return this.$route.params.id;
    }
  },
  watch: {
    portfolioId: {
      immediate: true,
      handler() {
        this.loadPortfolio()
        this.loadHistory()
      }
    }
  },
  methods: {
    async loadPortfolio () {
      this.portfolio = await this.$api.getPortfolio(this.portfolioId)
    },
    async loadHistory () {
      this.isDataLoading = true
      this.history = await this.$api.getHistory(this.portfolioId)
      this.isDataLoading = false
    }
  }
}
