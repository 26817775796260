<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Abi vormi täitmise kohta
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Näide</v-card-title>
      <v-card-text>
        <p>Investor alustas 1000€ investeerimist 2020-01-01. Jaanuari lõpuks oli ta portfell väärt 1100€. Veebruaris lisas ta palgast portfelli juurde 50€
        ning koos lisatud summaga oli portfell väärt veebruari lõpus 1200€. Märtsis pidi investor portfelist võtma 60€, et kinno minna. Portfell oli märtsi lõpuks, peale välja võetud raha väärt 1190€.
        </p>
        <p>Sellise portfell puhul peab investor looma uue portfelli, kus portfelli <strong>alguskuupäev</strong> on 2020-01-01 <strong>portfelli väärtus alguskuupäeval</strong> on 1000.</p>
        <p>Seejärel peab investor tegema järgmised andmete sissekanded.</p>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Väärtuskuupäev</th>
                <th class="text-right">Portfelli väärtus</th>
                <th class="text-right">Väline rahavoog</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>2020-01-31</td>
                <td class="text-right">1100</td>
                <td class="text-right">0</td>
              </tr>
              <tr>
                <td>2020-02-29</td>
                <td class="text-right">1200</td>
                <td class="text-right">50</td>
              </tr>
              <tr>
                <td>2020-03-31</td>
                <td class="text-right">1190</td>
                <td class="text-right">-60</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn text @click="dialog = false">Sulge</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  }
}
</script>