<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isDataLoading"
          :headers="headers"
          :items="values"
          class="elevation-1"
          dense
          :items-per-page="100"
          :sort-by="'date'"
          :sort-desc="true"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Portfell</v-toolbar-title>
              <v-divider class="mx-4" inset vertical />
              <v-spacer />
              <v-dialog v-model="formVisible" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    Lisa uus
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text>
                    <validation-observer ref="observer" v-slot="{}">
                      <form class="mt-3" @submit.prevent="submit">
                        <v-menu v-model="showDatePicker" :close-on-content-click="false" max-width="290">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="editedItem.date"
                              clearable
                              label="Väärtuskuupäev"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="editedItem.date = null"
                              outlined
                              :disabled="!!editedItem.id"
                            />
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            @change="showDatePicker = false"
                            :allowed-dates="isDateAllowed"
                            :events="historyDates"
                            event-color="green lighten-1"
                          />
                        </v-menu>
                        <validation-provider v-slot="{ errors }" name="Portfelli väärtus" rules="required">
                          <v-text-field
                            v-model="editedItem.portfolioValue"
                            :error-messages="errors"
                            label="Portfelli väärtus"
                            hint="Portfelli väärtus väärtuskuupäeva lõpu seisuga"
                            required
                            outlined
                            persistent-hint
                          />
                        </validation-provider>
                        <validation-provider  v-slot="{ errors }" name="Summa">
                          <v-text-field
                            v-model="editedItem.externalFlow"
                            :error-messages="errors"
                            label="Lisatud/välja võetud summa"
                            hint="Summa, mille oled portfelli lisanud või välja võtnud perioodil alates eelmisest väärtuskuupäevast kui praeguse väärtuskuupäevani. Juhul kui lisasid raha portfelli, on see postiivne number, juhul kui võtsid raha välja, siis negatiivne."
                            outlined
                            persistent-hint
                          />
                        </validation-provider>
                      </form>
                    </validation-observer>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="close" class="mr-2">
                      Sulge
                    </v-btn>
                    <help />
                    <v-btn v-if="!isExample" color="green" @click="save" dark>
                      Salvesta
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.portfolioValue`]="{ value }">
            <value :value="value" type="money" :forceNeutral="true" :bold="false" />
          </template>
          <template v-slot:[`item.externalFlow`]="{ value }">
            <value :value="value" type="money" :bold="false" />
          </template>
          <template v-slot:[`item.nav`]="{ value }">
            <value :value="value" type="nav" :forceNeutral="true" :bold="false" />
          </template>
          <template v-slot:[`item.navChange`]="{ value }">
            <value :value="value" type="percent" />
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon v-if="!isExample" small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import Value from '../../components/Value.vue'
import Help from '../../components/Help.vue'
import { PortfolioMixin } from '../../mixins/portfolio.mixin'

const emptyItem = {
  portfolioValue: '',
  externalFlow: '',
  date: '',
}

export default {
  components: { Value, Help },
  mixins: [ PortfolioMixin ],
  data () {
    return {
      showHelp: false,
      formVisible: false,
      showDatePicker: false,
      headers: [{
        text: 'Kuupäev',
        align: 'start',
        sortable: true,
        value: 'date',
      }, {
        text: 'Portfelli väärtus',
        align: 'end',
        sortable: false,
        value: 'portfolioValue',
      }, {
        text: 'Väline rahavoog',
        align: 'end',
        sortable: false,
        value: 'externalFlow',
      }, {
        text: 'NAV',
        align: 'end',
        sortable: false,
        value: 'nav',
      }, {
        text: 'NAV muutus',
        align: 'end',
        sortable: false,
        value: 'navChange',
      }, {
        text: 'Tegevused',
        value: 'actions',
        sortable: false,
        align: 'end',
      }],
      editedItem: {
        ...emptyItem
      },
      defaultItem: {},
    }
  },
  computed: {
    allowedDates () {
      if (!this.portfolio) {
        return []
      }

      let start = moment(this.portfolio.startDate)
      const end = moment()
      const list = []
      const added = this.historyDates

      while (start.isSameOrBefore(end)) {
        const date = start.format('YYYY-MM-DD')
        if (!added[date]) {
          list.push(date)
        }
        start = start.add(1, 'day')
      }


      return list
    },
    historyDates () {
      return this.history.reduce((dates, { date }) => {
        dates[date] = true
        return dates
      }, {})
    },
    values () {
      return this.history.map((item, i) => {
        const prev = this.history[i - 1]
        const prevNav = prev ? prev.nav : 1
        const navChange = ((item.nav - prevNav) / prevNav) * 100
        return { ...item, navChange }
      })
    },
    isExample () {
      return this.portfolio && this.portfolio.id === 'example'
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    close () {
      this.formVisible = false;
      this.editedItem = { ...emptyItem };
    },
    save () {
      const request = {
        externalFlow: parseFloat(this.editedItem.externalFlow) || 0,
        portfolioValue: parseFloat(this.editedItem.portfolioValue),
        date: moment(this.editedItem.date).endOf('day').toISOString(),
        portfolioId: this.portfolioId
      }
      const isUpdate = !!this.editedItem.id
      const url = isUpdate ? '/api/history/' + this.editedItem.id : '/api/history'
      const method = isUpdate ? 'put' : 'post'

      this.$http[method](url, request).then(() => {
        this.showNotification({ message: 'Andmed salvestatud', type: 'success' });
        this.loadData();
        if (!isUpdate) {
          this.editedItem.portfolioValue = '';
          this.editedItem.externalFlow = '';
          this.$refs.observer.reset();
        }
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' });
      })
    },
    isDateAllowed (date) {
      return this.allowedDates.includes(date)
    },
    editItem (item) {
      this.editedItem = {
        id: item.id,
        portfolioValue: item.portfolioValue,
        externalFlow: item.externalFlow,
        date: item.date,
      }
      this.formVisible = true
    },
    deleteItem (item) {
      this.$http.delete('/api/history/' + item.id).then(() => {
        this.showNotification({ message: 'Kustutatud', type: 'success' });
        this.loadData();
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' });
      })
    }
  }
}
</script>