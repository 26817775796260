<template>
  <div>
    <apexchart width="100%" :height="height" type="area" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
export default {
  props: {
    chartData: {
      required: false
    },
    height: {
      required: false,
      default: 500
    }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'area',
          height: this.height,
          toolbar: {
            tools: {
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            }
          }
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        stroke: {
          width: [3, 3, 3, 3, 3, 5],
          curve: 'smooth'
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: (val) => {
              return this.$formatCurrency.format(val)
            }
          }
        },
        tooltip: {
          shared: false,
          intersect: false,
          y: {
            formatter: (val) => {
              return this.$formatCurrency.format(val)
            }
          }
        },
      }
    },
    series () {
      return this.chartData.map((line) => {
        return {
          name: 'Name',
          data: line.data.map(point => ({
            x: point.time * 1000,
            y: point.value
          }))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  position: relative;
  margin-top: 0px;
}
.profile * {
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.info-area {
  z-index: 2;
  background: #fff;
  position: absolute;
  top: -15px;
  left: 0;
}
</style>