<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
              <v-toolbar-title>Minu portfellid</v-toolbar-title>
              <v-spacer />
              <v-btn @click="addNewPortfolio" color="primary" dark>
                Uus portfell
              </v-btn>
          </v-card-title>
          <v-card-text>
            <v-progress-linear :active="isDataLoading" indeterminate color="green darken-2"></v-progress-linear>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Portfelli nimi
                    </th>
                    <th class="text-left">
                      Alguskuupäev
                    </th>
                    <th class="text-left">
                      Algväärtus
                    </th>
                    <th class="text-left">
                      Väärtuskuupäev
                    </th>
                    <th class="text-left">
                      Väärtus
                    </th>
                    <th class="text-left">
                      Kogutootlus
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, k) in portfolios" :key="item.id + '-' + k" >
                    <td class="font-weight-bold" :class="{'red--text': item.id === 'example'}">{{ item.title }}</td>
                    <td>{{ item.startDate }}</td>
                    <td>{{ item.startValue }}</td>
                    <td>{{ item.latest && item.latest.date }}</td>
                    <td>{{ item.latest && item.latest.value }}</td>
                    <td>{{ item.latest && ((item.latest.nav - 1) * 100).toFixed(2) + '%' }}</td>
                    <td class="text-right">
                      <v-btn :to="{ name: 'analyze', params: { id: item.id } }" color="blue" small dark>Analüüs</v-btn>
                      <v-btn class="ml-2" :to="{ name: 'history', params: { id: item.id } }" color="secondary" small>Andmed</v-btn>
                      <v-btn class="ml-2" @click="edit(item)" color="green" dark small>Muuda</v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-alert v-if="participation === null && false" class="mt-10" type="success" text>
          Soovin osaleda <strong>Parim Investor 2024</strong> portfelli võistlusel. Registreerimine on avatud kuni veebrruar 2024 lõpuni.<br /><br />
          Osalemiseks peaks portfelli algväärtus olema vähemalt 10 000 EUR, portfelli väärtus avalik ja portfelli omanik olema avalik või teada olema korraldajale.
          Peale registreerimise perioodi lõppu mittekvalifitseeruvad portfellid eemaldatakse. Kõik ülejäänud portfellid on nähtavad üldises edetabelis.<br />
          <a target="_blank" href="https://docs.google.com/document/d/1yDO-iYh_JP0H6wa3IV0RHRWbxZSUC8nVx6g0Ibi5uGk/edit?usp=sharing">Loe täpsemalt reegleid</a><br />
          <v-select light class="mt-2" v-model="contestForm.portfolioId" :items="portfolioOptions" label="Vali portfell"></v-select>
          <v-btn v-if="contestForm.portfolioId" @click="registerToContest" class="mt-2" color="secondary" dark>Osalen</v-btn>
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog v-model="showPortfolioModal" max-width="800px">
      <validation-observer ref="observer" v-slot="{}">
        <form @submit.prevent="save">
          <v-card>
            <v-card-title>
              <span class="headline">{{editedItem.title || 'Portfell'}}</span>
            </v-card-title>
            <v-card-text>
              <validation-provider v-slot="{ errors }" name="Portfelli nimi" rules="required">
                <v-text-field
                  v-model="editedItem.title"
                  :error-messages="errors"
                  label="Portfelli nimi"
                  data-vv-as="Nimi"
                  hint="Nimetus portfelli eristamiseks. Avalikult seda ei kuvata."
                  required
                  outlined
                  persistent-hint
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" name="Portfelli kirjeldus" rules="required">
                <v-text-field
                  v-model="editedItem.description"
                  :error-messages="errors"
                  label="Portfelli kirjeldus"
                  hint="Portfolli kirjeldus. Avalikult seda ei kuvata."
                  required
                  outlined
                  persistent-hint
                />
              </validation-provider>
              <v-menu v-model="showDatePicker" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="editedItem.startDate"
                    clearable
                    label="Alguskuupäev"
                    hint="Mis kuupäevast alates soovid portfelli väärtusandmeid sisestada. Saad hiljem seda soovikorral muuta."
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="editedItem.startDate = null"
                    outlined
                    persistent-hint
                  />
                </template>
                <v-date-picker
                  v-model="editedItem.startDate"
                  @change="showDatePicker = false"
                />
              </v-menu>
              <validation-provider v-slot="{ errors }" name="Portfelli väärtus" rules="required">
                <v-text-field
                  v-model="editedItem.portfolioStartValue"
                  :error-messages="errors"
                  label="Portfelli väärtus alguskuupäeval"
                  hint="Kui palju oli portfell alguskuupäeval väärt (eurodes)."
                  required
                  outlined
                  persistent-hint
                />
              </validation-provider>
              <v-checkbox
                v-model="editedItem.isPrimary"
                label="Primaarne portfell"
                hide-details
              />
              <p>Juhul kui portfell on avalik, siis kuvatakse avalikult ainult kasutaja primaarset portfelli. Neid saab olla ainult üks.</p>
              <v-checkbox
                v-model="editedItem.navPublic"
                label="Portfelli NAV on avalik"
                hide-details
              />
              <p>Kuva avalikult ainut portfelli NAV väärtus.</p>
              <v-checkbox
                v-model="editedItem.valuePublic"
                label="Portfelli absoluutväärtus on avalik"
                hide-details
              />
              <p>Kuva avalikult nii portfelli NAV'i kui ka portfelli absoluutväärtust.</p>
              <template v-if="showAvatarForm">
                <v-img
                  style="float:right;"
                  v-if="selectedPortfolio && selectedPortfolio.id"
                  :width="100"
                  :height="100"
                  aspect-ratio="1"
                  :src="'/api/portfolio/' + selectedPortfolio.id + '/avatar/'"
                />
                <p>Kui lisad uue avatari, siis asendatakse see olemasolevaga. Pildi maksimaalne suurus on 2MB ning see peab olema jpg või png formaadis.</p>
                <div style="clear:both;"></div>
                <validation-observer ref="avatarObserver" v-slot="{}">
                  <form class="mt-10" @submit.prevent="avatarSubmit">
                    <ValidationProvider v-slot="{ errors }" name="photos">
                      <v-file-input
                        dense
                        outlined
                        v-model="avatarForm.files"
                        label="Foto"
                        show-size
                        counter
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                    <v-btn class="mt-3" type="submit" color="secondary" tile>Salvesta avatar</v-btn>
                  </form>
                </validation-observer>
                <v-dialog
                  v-if="selectedPortfolio"
                  v-model="deleteDialog"
                  persistent
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mt-10"
                      color="error"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Kustuta portfell
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Kas sa oled kindel?
                    </v-card-title>
                    <v-card-text>Kas oled kindel, et soovid portfelli kustutada? Selle tagajärel kustuvad kõik andmeid ja neid pole võimalik enam taastada!</v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        text
                        @click="deletePortfolio()"
                      >
                        Jah
                      </v-btn>
                      <v-btn
                        color="black"
                        text
                        @click="deleteDialog = false"
                      >
                        Ei
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text @click="close" class="mr-2">
                Sulge
              </v-btn>
              <help />
              <v-btn v-show="!isExample" type="submit" color="green" dark>
                Salvesta
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import Help from '../../components/Help.vue'

const emptyItem = {
  title: '',
  description: '',
  startDate: '',
  portfolioStartValue: '',
  valuePublic: true,
  navPublic: true,
  isPrimary: true,
}

export default {
  components: { Help },
  data () {
    return {
      participation: undefined,
      isDataLoading: false,
      deleteDialog: false,
      portfolios: [],
      showPortfolioModal: false,
      showDatePicker: false,
      selectedPortfolio: null,
      editedItem: {
        ...emptyItem
      },
      avatarForm: {
        files: []
      },
      contestForm: {
        portfolioId: undefined
      }
    }
  },
  computed: {
    ...mapState(['user']),
    showAvatarForm () {
      return this.user.isAdmin && this.selectedPortfolio
    },
    isExample () {
      return this.selectedPortfolio && this.selectedPortfolio.id === 'example'
    },
    portfolioOptions () {
      return this.portfolios.map(portfolio => ({
        text: portfolio.title,
        value: portfolio.id
      }))
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    addNewPortfolio () {
      this.showPortfolioModal = true
    },
    edit (item) {
      this.selectedPortfolio = item
      this.editedItem = {
        id: item.id,
        title: item.title,
        description: item.description,
        startDate: item.startDate,
        portfolioStartValue: item.startValue,
        valuePublic: item.valuePublic,
        navPublic: item.navPublic,
        isPrimary: item.isPrimary,
      }
      this.showPortfolioModal = true
    },
    async deletePortfolio () {
      await this.$api.deletePortfolio(this.selectedPortfolio.id)
      this.deleteDialog = false
      this.close()
    },
    close () {
      this.editedItem = { ...emptyItem }
      this.showPortfolioModal = false
      this.selectedPortfolio = null
      this.loadData()
    },
    save () {
      const isUpdate = !!this.editedItem.id
      const url = isUpdate ? '/api/portfolio/' + this.editedItem.id : '/api/portfolio'
      const method = isUpdate ? 'put' : 'post'

      this.$http[method](url, {
        ...this.editedItem,
        startDate: moment(this.editedItem.startDate).endOf('day').toISOString(),
        portfolioStartValue: parseFloat(this.editedItem.portfolioStartValue)
      }).then(() => {
        this.close()
        this.loadData()
        this.showNotification({ message: 'Andmed salvestatud', type: 'success' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    avatarSubmit () {
      if (!this.avatarForm.files) {
        return
      }

      const data = new FormData()
      data.append('file', this.avatarForm.files)

      this.$http.post('/api/portfolio/' + this.selectedPortfolio.id +'/avatar', data, {
        headers: {'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.showNotification({ message: 'Avatar salvestatud', type: 'success' })
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    registerToContest () {
      if (!this.contestForm.portfolioId) {
        return
      }

      this.$http.put('/api/portfolio/' + this.contestForm.portfolioId +'/register/202', {}, {})
      .then(() => {
        this.showNotification({ message: 'Portfell edukalt registreeritud', type: 'success' })
        this.loadData()
      }).catch(err => {
        this.showNotification({ message: err.response.data.message, type: 'error' })
      })
    },
    loadData () {
      this.isDataLoading = true
      this.participation = undefined
      this.$http.get('/api/portfolio/me').then(response => {
        this.portfolios = response.data.data
      })
      .catch(() => {})
      .then(() => {
        this.isDataLoading = false
      })
      this.$http.get('/api/contest/2022/me').then(response => {
        this.participation = response.data
      })
      .catch(() => {})
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>