<template>
  <v-container fluid>
    <v-row>
      <v-col class="light-green lighten-2 white--text">
        <div class="text-center">Portfelli väärtus</div>
        <h3 class="text-center">{{$formatCurrency.format(periodNavAnalyze.endPortfolioValue)}}</h3>
      </v-col>
      <v-col class="light-green lighten-2 white--text">
        <div class="text-center">Portfelli muutus</div>
        <h3 class="text-center">{{$formatCurrency.format(periodNavAnalyze.portfolioValueChange)}}</h3>
      </v-col>
      <v-col class="light-green lighten-2 white--text">
        <div class="text-center">Annualiseeritud tootlus</div>
        <h3 class="text-center">{{$formatNumber.format(periodNavAnalyze.navPerformanceAnnualized)}}%</h3>
      </v-col>
      <v-col class="light-green lighten-2 white--text">
        <div class="text-center">Kogu tootlus</div>
        <h3 class="text-center">{{$formatNumber.format(periodNavAnalyze.navPerformance)}}%</h3>
      </v-col>
      <v-col class="light-green lighten-1 d-flex align-center justify-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="dark" dark v-bind="attrs" v-on="on">
              {{period.title}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in periodOptions" :key="index" link @click="period = item">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog
          ref="dialog"
          v-model="todayModal"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
          </template>
          <v-date-picker
            v-model="today"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="todayModal = false"
            >
              Sulge
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <v-btn class="ml-2" @click="showChooseCompareTo = true">
          <v-icon>mdi-wrench</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="portfolio" class="mt-3">
      <v-col class="blue-grey white--text d-flex align-center">
        <strong>{{portfolio.title}}</strong>
      </v-col>
      <v-col v-for="item in trailingReturns" :key="item.key" :class="item.color">
        <div class="text-center">{{item.title}}</div>
        <h4 class="text-center">
          {{$formatNumber.format(item.return)}}%<span v-if="item.divisor > 1">*</span>
        </h4>
      </v-col>
    </v-row>
    <v-row v-if="comparePortfolio" class="mt-3">
      <v-col class="blue-grey lighten-3 d-flex align-center">
        {{comparePortfolio.displayName}}
      </v-col>
      <v-col v-for="item in trailingReturns" :key="item.key" :class="item.compareColor" class="d-flex align-center justify-center">
        <h5 class="text-center">{{$formatNumber.format(item.compareReturn)}}%</h5>
        <span v-if="item.divisor > 1">*</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <small>*Annualiseeritud tootlus</small>
        <small v-if="todayFormated" class="ml-5">Kuupäev: {{ todayFormated }}</small>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col>
        <v-card>
           <v-card-title>
            <v-toolbar-title>Portfelli väärtus</v-toolbar-title>
           </v-card-title>
          <v-card-text>
            <portfolio-chart :chartData="portfolioValueChartData" :height="300" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            <v-toolbar-title>Portfelli tootlus</v-toolbar-title>
          </v-card-title>
          <v-card-text>
            <chart :chartData="chartData" :height="300" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showChooseCompareTo"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Vali portfell
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="comparePortfolioSelect"
            :items="compareOptions"
            label="Võrdlusportfell"
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="chooseCompare">
            Vali
          </v-btn>
          <v-btn color="red darken-1" text @click="showChooseCompareTo = false">
            Tühista
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PortfolioMixin } from '../../mixins/portfolio.mixin'
import Chart from './../../components/Chart'
import PortfolioChart from './../../components/PortfolioChart'
import { mapPortfolioData } from '../../helpers'
import moment from 'moment'
import { TopMixin } from '../../mixins/top.mixin'

const defaultPeriod = {
  title: 'Kogu periood',
  key: 'total'
}

const colorPriority = ['darken-2', 'darken-1', 'lighten-1', 'lighten-2', 'lighten-3', 'lighten-4', 'lighten-5']

export default {
  components: { Chart, PortfolioChart },
  mixins: [ PortfolioMixin, TopMixin ],
  data () {
    return {
      navAnalyzes: {},
      comparePortfolioId: 'e536366d-58a8-4267-8304-15fac4549994',
      comparePortfolioSelect: 'e536366d-58a8-4267-8304-15fac4549994',
      comparePortfolio: null,
      comparePortfolioHistory: [],
      period: defaultPeriod,
      showChooseCompareTo: false,
      publicPortfolios: [],
      todayModal: false,
      today: null
    }
  },
  computed: {
    ...mapState(['user']),
    todayFormated () {
      return this.today ? moment(this.today).format('YYYY-MM-DD') : ''
    },
    periodOptions () {
      return [defaultPeriod, {
        title: 'Jooksev aasta',
        key: 'ytd'
      }, {
        title: '1 aasta',
        key: 'year1'
      }, {
        title: '3 aastat',
        key: 'year3'
      }, {
        title: '5 aastat',
        key: 'year5'
      }].filter(period => {
        return this.navAnalyze && this.navAnalyze[period.key]
      })
    },
    compareOptions () {
      return this.publicPortfolios.map(portfolio => ({
        text: portfolio.displayName,
        value: portfolio.id
      }))
    },
    minDate () {
      if (this.navAnalyze) {
        return moment(this.navAnalyze[this.period.key].startDate)
      }
      return moment.max(this.portfolios.map(item => moment(item.history[0].date)))
    },
    portfolios () {
      if (!this.portfolio || !this.history.length || !this.comparePortfolio || !this.comparePortfolioHistory.length) {
        return []
      }

      return [{
        portfolio: this.portfolio,
        history: this.history
      }, {
        portfolio: this.comparePortfolio,
        history: this.comparePortfolioHistory
      }].map(portfolio => {
        portfolio.navAnalyze = (this.navAnalyzes[this.portfolio.id] || {}).data
        return portfolio
      })
    },
    chartData () {
      return this.portfolios.map(item => {
        return mapPortfolioData(item.history, item.portfolio, {
          minDate: this.minDate,
          maxDate: this.today,
          includePrev: true
        })
      })
    },
    navAnalyze () {
      if (!this.portfolioId) {
        return
      }

      const analyze = this.navAnalyzes[this.portfolioId]

      return analyze && analyze.data || undefined
    },
    compareNavAnalyze () {
      if (!this.comparePortfolio) {
        return
      }

      const analyze = this.navAnalyzes[this.comparePortfolio.id]
      return analyze && analyze.data || undefined
    },
    periodNavAnalyze () {
      if (!this.navAnalyze || !this.navAnalyze[this.period.key]) {
        return {}
      }
      return this.navAnalyze[this.period.key]
    },
    ytd () {
      if (!this.navAnalyze) {
        return  {}
      }
      return this.navAnalyze.ytd
    },
    totalPerformance () {
      if (!this.navAnalyze) {
        return
      }
      return this.navAnalyze.total.navPerformance
    },
    totalPerformanceAnnualized () {
      if (!this.navAnalyze) {
        return
      }
      return this.navAnalyze.total.navPerformanceAnnualized
    },
    portfolioValueChartData () {
      if (!this.history || !this.portfolio) {
        return []
      }
      return [mapPortfolioData(this.history, this.portfolio, {
        key: 'portfolioValue',
        minDate: this.minDate,
        maxDate: this.maxDate,
        includePrev: true
      })]
    },
    trailingReturns () {
      return [{
        title: '1 kuu',
        key: 'month1'
      }, {
        title: '3 kuud',
        key: 'month3'
      }, {
        title: '6 kuud',
        key: 'month6'
      }, {
        title: 'Jooksev aasta',
        key: 'ytd'
      }, {
        title: '1 aasta',
        key: 'year1'
      }, {
        title: '3 aastat',
        key: 'year3'
      }, {
        title: '5 aastat',
        key: 'year5'
      }, {
        title: 'Kokku',
        key: 'total'
      }]
      .filter(item => {
        return this.navAnalyze?.[item.key]
      })
      .map((item) => {
        item.return = this.navAnalyze?.[item.key].navPerformanceAnnualized
        item.divisor = this.navAnalyze?.[item.key].divisor
        item.compareReturn = this.compareNavAnalyze && this.compareNavAnalyze[item.key] && this.compareNavAnalyze[item.key].navPerformanceAnnualized
        return item
      })
      .map((item, index, all) => {
        item.position = all.filter(i => item.return >= 0 ? i.return >= item.return : i.return <= item.return).length 
        item.comparePosition = all.filter(i => item.compareReturn >= 0 ? i.compareReturn >= item.compareReturn : i.compareReturn <= item.compareReturn).length 
        item.color = (item.return >= 0 ? 'green' : 'red') + ' ' + colorPriority[item.position - 1]
        item.compareColor = (item.return >= 0 ? 'lime' : 'orange') + ' ' + colorPriority[item.comparePosition - 1]
        return item
      })
    }
  },
  watch: {
    comparePortfolioId: {
      immediate: true,
      async handler (portfolioId) {
        this.loadNavAnalyze(portfolioId, this.today)
        this.comparePortfolioHistory = await this.$api.getPortfolioHistoryPublic(portfolioId)
        this.comparePortfolio = await this.$api.getPortfolio(portfolioId)
      }
    },
    portfolio: {
      immediate: true,
      handler (portfolio) {
        if (!portfolio) {
          return
        }
        this.loadNavAnalyze(portfolio.id, this.today)
      }
    },
    today: {
       handler () {
        if (this.portfolio) {
          this.loadNavAnalyze(this.portfolio.id, this.today)
        }
        if (this.comparePortfolio) {
          this.loadNavAnalyze(this.comparePortfolio.id, this.today)
        }
      }
    },
    navAnalyze (analyze) {
      if (analyze && !this.today) {
        this.today = moment(analyze.ytd.endDate).subtract(12,'h').format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    ...mapActions(['showNotification']),
    async loadNavAnalyze (portfolioId, today) {
      const data = await this.$api.getPortfolioNavAnalyze(portfolioId, today)
  
      this.$set(this.navAnalyzes, portfolioId, {
        portfolioId,
        data
      })
    },
    loadYearData () {
      this.top = null
      this.$http.get('/api/portfolio/top/nav/' + this.contest.contestId, {})
        .then(response => {
          this.publicPortfolios = response.data
        })
    },
    chooseCompare () {
      this.comparePortfolioId = this.comparePortfolioSelect
      this.showChooseCompareTo = false
    }
  },
  mounted () {
    this.loadYearData()
  }
}
</script>